<template>
  <v-app class="background">
    <Toolbar />
    <v-container>
      <v-layout row wrap justify-center align-content-center pt-16 class="bottom">
        <v-flex xs10 lg4>
          <v-card max-width="350">
            <v-card-text>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/PineapplePassionFruit.jpg"
              ></v-img>
            </v-card-text>
          </v-card>
          <v-card
            style="position: absolute; top: 340px; left: 158px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
          >
            <v-card-title data-aos="fade-up" data-aos-easing="ease-in-sine" class="fontHeader">
              Pineapple Passion Fruit
            </v-card-title>

            <v-card-subtitle
              class="text-left pt-4 brown--text"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              ช่วงโควิดหลายคนคงได้ยินข่าวพืชผลทางการเกษตรตกต่ำ ผลไม้หน้าสวนขายกันโลละไม่กี่บาท
              ชาวสวนและเกษตรกรต่างเดือดร้อน เนื่องจากสินค้าล้นตลาด ขายไม่ได้ราคา
              และปัญหาเศรษฐกิจที่ทุกคนต่างรู้กันดีว่าซบเซาขนาดไหน แถมยังมีดราม่าเรื่อง food waste
              เข้ามาเกี่ยวอีก ด้วยเหตุผลข้างต้น เราจึงได้นำเสาวรสจากสวนป้าพร
              ซึ่งเป็นสวนแบบเกษตรอินทรีย์ในจังหวัดกระบี่ มาแปรรูปและรังสรรค์เป็นเจลาโต้รส Pineapple
              Passion Fruit ขึ้นมา
            </v-card-subtitle>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon color="orange lighten-2" @click="show = !show">
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text class="text-left brown--text">
                  เป็นการสร้างมูลค่าให้แก่สินค้าเกษตร และช่วยให้คุณป้าพอมีรายได้อย่างต่อเนื่อง
                  มีกำลังใจในการปลูก ถึงแม้ว่า rest is more เราจะเป็นเพียงร้านเจลาโต้โฮมเมดเล็กๆ
                  ไม่ได้มีกำลังการผลิตมากมาย แต่เรามีจุดมุ่งหมายและจุดยืนที่ชัดเจน
                  ว่าเราต้องการเป็นส่วนหนึ่งในการขับเคลื่อนสังคมและคุณภาพชีวิตของคนในชุมชนให้เดินหน้าต่อไปได้
                  รวมถึงส่งเสริมด้านการเกษตรของคนในพื้นที่ต่างๆ สุดท้ายขอขอบคุณผู้สนับสนุนเบื้องหลัง
                  อย่างร้าน เจ๊มะนาว ปูไข่ดองน้ำปลากวน ที่ได้นำเจลาโต้รสชาตินี้ไปขายที่ร้านด้วย
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-flex>
        <v-flex xs10 lg4>
          <v-card max-width="350">
            <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
              <v-img src="@/assets/img/HoneyLemonSorbetto.jpg"></v-img>
            </v-card-text>
          </v-card>
          <v-card
            style="position: absolute; top: 340px; left: 552px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
            height="375"
          >
            <v-card-title data-aos="fade-up" data-aos-easing="ease-in-sine" class="fontHeader text-center">
              Honey Lemon Sorbetto
            </v-card-title>

            <v-card-subtitle
              class="text-left pt-4 brown--text"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              รสสัมผัสแรกของซอร์เบทโต้รส Honey Lemon คือ รสเปรี้ยวกลมกล่อมจากผลเลมอนสด
              ตั้งแต่คำแรกที่ทาน แต่เมื่อคุณกลืนลงไป กลับได้รสหวานของน้ำผึ้งแทรกอยู่ในคอ
              เปรียบเหมือน aftertaste พร้อมกลิ่นหอมของผิวเลมอนที่เรานำไป infuse ในเบส
              ยังเป็นการเพิ่มอรรถรสความซับซ้อนของซอร์เบทโต้รสนี้ และเนื้อสัมผัสที่เนียน นุ่ม
              ไม่เป็นเกล็ดน้ำแข็ง ช่วยให้ได้รสชาติที่เด่นชัด สมกับชื่อ Honey Lemon Sorbetto จริงๆ
            </v-card-subtitle>
          </v-card>
        </v-flex>
        <v-flex xs10 lg4>
          <v-card max-width="350">
            <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
              <v-img src="@/assets/img/YuzuMAndarinSorbetto.jpg"></v-img>
            </v-card-text>
          </v-card>
          <v-card
            style="position: absolute; top: 340px; left: 947px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
                 height="375"
          >
            <v-card-title data-aos="fade-up" data-aos-easing="ease-in-sine" class="fontHeader text-center">
              Yuzu x MAndarin Sorbetto
            </v-card-title>

            <v-card-subtitle
              class="text-left pt-4 brown--text"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              Concept : Luxury taste & Local orange Luxury taste :
              รสพรีเมียมของส้มยุสุที่นำเข้าจากเกาหลี ( 유자 ) ด้วยรสชาติที่เปรี้ยวอ่อนๆละมุนๆ
              มีกลิ่นหอมสดชื่นที่ใครๆก็ต่างหลงรัก เอกลักษณ์เฉพาะที่หาจากส้มที่ไหนไม่ได้ Local orange
              : ส้มแมนดาริน รสชาติหวานฉ่ำ ตัดรสเปรี้ยวของส้มยุสุจนได้รสเปรี้ยวอมหวานชัดเจน
              การนำส้มทั้งสองชนิดมาทำเจลาโต้ เราคงรสและกลิ่นของส้มไว้ทั้งหมด
              เพื่อให้คุณได้กลิ่นอายทรอปิคอล ความมีชีวิตชีวา ความสดใส
              เหมือนคุณได้ทานเจลาโต้เย็นๆท่ามกลางแสงอาทิตย์อบอุ่น
            </v-card-subtitle>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- <v-layout row wrap pb-2>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path
          fill="#9C7656"
          fill-opacity="1"
          d="M0,224L48,218.7C96,213,192,203,288,208C384,213,480,235,576,218.7C672,203,768,149,864,138.7C960,128,1056,160,1152,176C1248,192,1344,192,1392,192L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </v-layout> -->

    <Footer />
  </v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Toolbar,
    Footer
  },
  data: () => ({
    show: false,
    showMatcha: false
  })
}
</script>
<style scoped>
.fontHeader {
  font-size: 19px;
  color: #9c7656;
}
.bottom {
  padding-bottom: 670px;
}
.background {
  background-color: #EDEDE7;
}
</style>
